import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@mui/material';
import RouterLink from 'next/link';

import { AppError } from '@/types';
import PageLayout from '@/components/layout/PageLayout';

type Props = {
  error: AppError;
};

function AppErrorWrapper({ error: { headline, message, statusCode } }: Props) {
  const { t } = useTranslation();

  let errorHeadline = headline ?? '';
  if (!headline) {
    errorHeadline = t(`errorPage.${statusCode}.headline`);
  }
  let errorMessage = message ?? '';
  if (statusCode === 404 && !message) {
    errorMessage = t(`errorPage.${statusCode}.message`);
  }

  return (
    <PageLayout title={t(`pageTitle.${statusCode}`)}>
      <Typography color="textPrimary" gutterBottom variant="h1">
        {errorHeadline}
      </Typography>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {errorMessage}
        </Typography>
        <RouterLink href="/" passHref legacyBehavior>
          <Link href="to-be-replaced">{t('errorPage.btnLabel')}</Link>
        </RouterLink>
      </Box>
    </PageLayout>
  );
}

export default AppErrorWrapper;
